import { 
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_FAIL,

  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,

  ORDER_DETAIL_ID_REQUEST,
  ORDER_DETAIL_ID_SUCCESS,
  ORDER_DETAIL_ID_FAIL,

  ORDERS_UPCOMING_REQUEST,
  ORDERS_UPCOMING_SUCCESS,
  ORDERS_UPCOMING_FAIL,

  PREPARING_REQUEST,
  PREPARING_SUCCESS,
  PREPARING_FAIL,

  READY_REQUEST,
  READY_SUCCESS,
  READY_FAIL,

  ORDERS_ACCEPT_REQUEST,
  ORDERS_ACCEPT_SUCCESS,
  ORDERS_ACCEPT_FAIL,

  ORDERS_REJECT_REQUEST,
  ORDERS_REJECT_SUCCESS,
  ORDERS_REJECT_FAIL,

  ORDERS_READY_REQUEST,
  ORDERS_READY_SUCCESS,
  ORDERS_READY_FAIL,

  ORDERS_FAILED_REQUEST,
  ORDERS_FAILED_SUCCESS,
  ORDERS_FAILED_FAIL,

  ORDRES_REQUEST_DRIVER_REQUEST,
  ORDERS_REQUEST_DRIVER_SUCCESS,
  ORDERS_REQUEST_DRIVER_FAIL,

  ORDERS_NOW_REQUEST,
  ORDERS_NOW_SUCCESS,
  ORDERS_NOW_FAIL,
} from '../constant'
import axios from 'axios'
import { api_url, authHeader } from '../api/ApiUtills'

export const OrderAction = (id) => async (dispatch) => {
  try{
    dispatch({type:ORDER_REQUEST})
      const {data} = await axios.get(`${api_url}/orders`,  authHeader()
    )
    dispatch({
      type:ORDER_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDER_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderSearchAction=(query, type) => async (dispatch) =>{
  try{
    dispatch({type:ORDER_REQUEST})
      const {data} = await axios(`${api_url}/orders/search?type=${type}&q=${query}`, 
        authHeader(),
        {withCredentials: true},
    )
    dispatch({
      type:ORDER_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDER_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderDetailsAction=(id) => async (dispatch) =>{
  try{
    dispatch({type:ORDER_DETAIL_REQUEST})
      const {data} = await axios.get(`${api_url}/orders/${id}`, authHeader()
    )      
    dispatch({
      type:ORDER_DETAIL_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDER_DETAIL_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderDetailIdAction=(id) => async (dispatch) =>{
  try{
    dispatch({type:ORDER_DETAIL_ID_REQUEST})
      const {data} = await axios.get(`${api_url}/orders/${id}`, authHeader()
    )      
    dispatch({
      type:ORDER_DETAIL_ID_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDER_DETAIL_ID_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrdersUpcomingAction=() => async (dispatch) =>{
  try{
    dispatch({type:ORDERS_UPCOMING_REQUEST})
      const {data} = await axios.get(`${api_url}/orders/upcoming`, authHeader()
    )
    dispatch({
      type:ORDERS_UPCOMING_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDERS_UPCOMING_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const PreparingAction=() => async (dispatch) =>{
  try{
    dispatch({type:PREPARING_REQUEST})
      const {data} = await axios.get(`${api_url}/orders/preparing`, authHeader()
    )
    dispatch({
      type:PREPARING_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:PREPARING_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const ReadyAction=() => async (dispatch) =>{
  try{
    dispatch({type:READY_REQUEST})
    const {data} = await axios.get(`${api_url}/orders/ready`,
      authHeader()
    )
    dispatch({
      type:READY_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:READY_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const FailedAction=() => async (dispatch) =>{
  try{
    dispatch({type:ORDERS_FAILED_REQUEST})
    const {data} = await axios.get(`${api_url}/orders/failed`,
      authHeader()
    )
    dispatch({
      type:ORDERS_FAILED_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDERS_FAILED_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderAcceptAction=(id) => async (dispatch) =>{
  try{
    dispatch({type:ORDERS_ACCEPT_REQUEST})
      const {data} = await axios.post(`${api_url}/orders/${id}/accept`,
      {withCredentials: true}, authHeader()
    )
    dispatch({
      type:ORDERS_ACCEPT_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDERS_ACCEPT_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderRejectAction=(id) => async (dispatch) =>{
  try{
    dispatch({type:ORDERS_REJECT_REQUEST})
      const {data} = await axios.post(`${api_url}/orders/${id}/reject`,
        {withCredentials: true},
        authHeader()
    )
    dispatch({
      type:ORDERS_REJECT_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDERS_REJECT_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderReadyAction=(id) => async (dispatch) =>{
  try{
    dispatch({type:ORDERS_READY_REQUEST})
      const {data} = await axios.post(`${api_url}/orders/${id}/ready_qualify`,
        {withCredentials: true},
        authHeader()
      )
    dispatch({
      type:ORDERS_READY_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDERS_READY_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderRequestDeliveryAction = (id) => async (dispatch) => {
  try{
    dispatch({type:ORDRES_REQUEST_DRIVER_REQUEST})
      const {data} = await axios.post(`${api_url}/orders/${id}/request_driver`,
        {withCredentials: true},
        authHeader()
      )
    dispatch({
      type:ORDERS_REQUEST_DRIVER_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDERS_REQUEST_DRIVER_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderNowAction = (id) => async (dispatch) => {
  try{
    dispatch({type:ORDERS_NOW_REQUEST})
      const {data} = await axios.post(`${api_url}/orders/${id}/order_now`,
        {withCredentials: true},
        authHeader()
      )
    dispatch({
      type:ORDERS_NOW_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDERS_NOW_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}