export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const ORDER_REQUEST = 'ORDER_REQUEST'
export const ORDER_SUCCESS = 'ORDER_SUCCESS'
export const ORDER_FAIL = 'ORDER_FAIL'

export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST'
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS'
export const ORDER_DETAIL_FAIL = 'ORDER_DETAIL_FAIL'

export const ORDER_DETAIL_ID_REQUEST = 'ORDER_DETAIL_ID_REQUEST'
export const ORDER_DETAIL_ID_SUCCESS = 'ORDER_DETAIL_ID_SUCCESS'
export const ORDER_DETAIL_ID_FAIL = 'ORDER_DETAIL_ID_FAIL'

export const ORDERS_UPCOMING_REQUEST = 'ORDERS_UPCOMING_REQUEST'
export const ORDERS_UPCOMING_SUCCESS = 'ORDERS_UPCOMING_SUCCESS'
export const ORDERS_UPCOMING_FAIL = 'ORDERS_UPCOMING_FAIL'

export const ORDERS_ACCEPT_REQUEST = 'ORDERS_ACCEPT_REQUEST'
export const ORDERS_ACCEPT_SUCCESS = 'ORDERS_ACCEPT_SUCCESS'
export const ORDERS_ACCEPT_FAIL = 'ORDERS_ACCEPT_FAIL'

export const ORDERS_REJECT_REQUEST = 'ORDERS_REJECT_REQUEST'
export const ORDERS_REJECT_SUCCESS = 'ORDERS_REJECT_SUCCESS'
export const ORDERS_REJECT_FAIL = 'ORDERS_REJECT_FAIL'

export const ORDERS_READY_REQUEST = 'ORDERS_READY_REQUEST'
export const ORDERS_READY_SUCCESS = 'ORDERS_READY_SUCCESS'
export const ORDERS_READY_FAIL = 'ORDERS_READY_FAIL'

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST'
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS'
export const SETTINGS_FAIL = 'SETTINGS_FAIL'

export const PREPARING_REQUEST = 'PREPARING_REQUEST'
export const PREPARING_SUCCESS = 'PREPARING_SUCCESS'
export const PREPARING_FAIL    = 'PREPARING_FAIL'

export const READY_REQUEST = 'READY_REQUEST'
export const READY_SUCCESS = 'READY_SUCCESS'
export const READY_FAIL    = 'READY_FAIL'

export const MENU_REQUEST = 'MENU_REQUEST'
export const MENU_SUCCESS = 'MENU_SUCCESS'
export const MENU_FAIL    = 'MENU_FAIL'

export const MENU_DETAIL_REQUEST = 'MENU_DETAIL_REQUEST'
export const MENU_DETAIL_SUCCESS = 'MENU_DETAIL_SUCCESS'
export const MENU_DETAIL_FAIL    = 'MENU_DETAIL_FAIL'

export const ORDERS_COUNTS_REQUEST = 'ORDERS_COUNTS_REQUEST'
export const ORDERS_COUNTS_SUCCESS = 'ORDERS_COUNTS_SUCCESS'
export const ORDERS_COUNTS_FAIL = 'ORDERS_COUNTS_FAIL'

export const TOGGLE_HEAVY_RAIN_REQUEST = 'TOGGLE_HEAVY_RAIN_REQUEST'
export const TOGGLE_HEAVY_RAIN_SUCCESS = 'TOGGLE_HEAVY_RAIN_SUCCESS'
export const TOGGLE_HEAVY_RAIN_FAIL = 'TOGGLE_HEAVY_RAIN_FAIL'

export const TOGGLE_CLOSED_REQUEST = 'TOGGLE_CLOSED_REQUEST'
export const TOGGLE_CLOSED_SUCCESS = 'TOGGLE_CLOSED_SUCCESS'
export const TOGGLE_CLOSED_FAIL = 'TOGGLE_CLOSED_FAIL'

export const TOGGLE_WEATHER_REQUEST = 'TOGGLE_WEATHER_REQUEST'
export const TOGGLE_WEATHER_SUCCESS = 'TOGGLE_WEATHER_SUCCESS'
export const TOGGLE_WEATHER_FAIL = 'TOGGLE_WEATHER_FAIL'

export const TOGGLE_PAUSE_REQUEST = 'TOGGLE_PAUSE_REQUEST'
export const TOGGLE_PAUSE_SUCCESS = 'TOGGLE_PAUSE_SUCCESS'
export const TOGGLE_PAUSE_FAIL = 'TOGGLE_PAUSE_FAIL'

export const WEATHER_REQUEST = 'WEATHER_REQUEST'
export const WEATHER_SUCCESS = 'WEATHER_SUCCESS'
export const WEATHER_FAIL = 'WEATHER_FAIL'

export const CLOSED_REQUEST = 'CLOSED_REQUEST'
export const CLOSED_SUCCESS = 'CLOSED_SUCCESS'
export const CLOSED_FAIL = 'CLOSED_FAIL'

export const ORDER_PAGE_REQUEST = 'ORDER_PAGE_REQUEST'
export const ORDER_PAGE_SUCCESS = 'ORDER_PAGE_SUCCESS'
export const ORDER_PAGE_FAIL    = 'ORDER_PAGE_FAIL'

export const ORDERS_HISTORY_REQUEST = 'ORDERS_HISTORY_REQUEST'
export const ORDERS_HISTORY_SUCCESS = 'ORDERS_HISTORY_SUCCESS'
export const ORDERS_HISTORY_FAIL = 'ORDERS_HISTORY_FAIL'

export const ORDERS_FAILED_REQUEST = 'ORDERS_FAILED_REQUEST'
export const ORDERS_FAILED_SUCCESS = 'ORDERS_FAILED_SUCCESS'
export const ORDERS_FAILED_FAIL = 'ORDERS_FAILED_FAIL'

export const ORDRES_REQUEST_DRIVER_REQUEST = 'ORDRES_REQUEST_DRIVER_REQUEST'
export const ORDERS_REQUEST_DRIVER_SUCCESS = 'ORDERS_REQUEST_DRIVER_SUCCESS'
export const ORDERS_REQUEST_DRIVER_FAIL = 'ORDERS_REQUEST_DRIVER_FAIL'

export const ORDERS_NOW_REQUEST = 'ORDERS_NOW_REQUEST'
export const ORDERS_NOW_SUCCESS = 'ORDERS_NOW_SUCCESS'
export const ORDERS_NOW_FAIL = 'ORDERS_NOW_FAIL'
