import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { OrderRejectAction } from '../../redux/actions/OrderAction'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";

export default function CancelModal({isOpen,setIsOpen}) {

  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { singleOrder } = useSelector(state=>state.singleOrderDetails)
  const id = singleOrder?.id

  const HandleClick = () =>{
    dispatch(OrderRejectAction(id))
    window.location.reload(); 
    setIsOpen()
  }
  
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed custom_inset z-10 overflow-y-auto"
          onClose={setIsOpen}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block custom_bg w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl cancel_order_modal">
                <Dialog.Title
                  as="h3"
                  className="cancel_order_modal_heading text-white"
                >
                  
                </Dialog.Title>
                <div className="">
                  <p className="cancel_order_modal_p text-white">
                    Are you sure you want to cancel this order?
                  </p>
                </div>

                <div className="order_cancel_button justify-end flex">
                  <button
                    type="button"
                    className="inline-flex justify-center order_cancel_button_yes text-white"
                    onClick={HandleClick}
                  >
                    Yes
                  </button>
                   <button
                    type="button"
                    className="inline-flex justify-center order_cancel_button_no text-white"
                    onClick={setIsOpen}
                  >
                    No
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
