import {createStore , combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools } from 'redux-devtools-extension'
import { LoginReducer } from './reducers/LoginReducer'
import { 
	OrderReducer, 
	OrderDetailReducer, 
	OrdersUpcomingReducer, 
  PreparingReducer,
  ReadyReducer,
  OrderAcceptReducer,
  OrderRejectReducer,
  OrderReadyReducer,
	FailedReducer
 } from './reducers/OrderReducer'
import { MenuReducer, MenuDetailReducer } from './reducers/MenuReducer'
import { OrdercountsReducer } from './reducers/OrdercountsReducer'
import { 
	WeatherReducer,
	HeavyRainReducer,
	ClosedReducer,
	SettingReducer
} from './reducers/SettingReducer'
import { OrdersHistoryReducer } from './reducers/OrdersHistoryReducer'

const reducer= combineReducers({
	userLogin: LoginReducer,
	userOrder: OrderReducer,
	singleOrderDetails: OrderDetailReducer,
	upcomingOrder: OrdersUpcomingReducer,
	weather: WeatherReducer,
	prepare: PreparingReducer,
	ready: ReadyReducer,
	menu: MenuReducer,
	menudetail: MenuDetailReducer,
	acceptOrder: OrderAcceptReducer,
	rejectOrder: OrderRejectReducer,
	readyOrder: OrderReadyReducer,
	OrderCounts: OrdercountsReducer,
	heavyrain: HeavyRainReducer,
	closed_restaurant: ClosedReducer,
	settings: SettingReducer,
	history: OrdersHistoryReducer,
	failedOrder: FailedReducer
})

const userInfoFromStorage = localStorage.getItem('userInfo')? 
  JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
	userLogin : {
		userInfo: userInfoFromStorage,
		isLoggedIn: userInfoFromStorage !== null,
	}
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store