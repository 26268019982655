import React, { useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {useReactToPrint} from 'react-to-print';
import { ComponentToPrint } from '../Print'
import { GrillToPrint } from '../Receipt/GrillReceipt'
import { BarToPrint } from '../Receipt/BarReceipt'
import { KitchenToPrint } from '../Receipt/KitchenReceipt'
import { AllReceiptToPrint } from '../Receipt/Allreceipt'
import { useSelector } from 'react-redux'

export default function PrintModel({printModal,setPrintModal}) {
  
  const {singleOrder} = useSelector(state=>state.singleOrderDetails)

  const componentRef = useRef();
  const grillRef = useRef();
  const barRef = useRef();
  const kitchenRef = useRef();
  const allreceipt = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleGrill = useReactToPrint({
    content: () => grillRef.current,
  });

  const handleBar = useReactToPrint({
    content: () => barRef.current,
  });

  const handleKitchen = useReactToPrint({
    content: () => kitchenRef.current,
  });
  
  const handleAllReceipt = useReactToPrint({
    content: () => allreceipt.current,
  });

  const orderPrintHandler = (e) =>{
    e.preventDefault()
    handlePrint()
  }
  
  
  return (
    <>
      <Transition appear show={printModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setPrintModal}
          >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
              >
              <div className="inline-block w-full custom_bg max-w-md p-6 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="div"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                </Dialog.Title>
                <div className="mt-4">
                    <div style={{ display: "none" }}>
                      <ComponentToPrint ref={componentRef}  />
                    </div>

                    <div style={{ display: "none" }}>
                      <GrillToPrint ref={grillRef}  />
                    </div>

                    <div style={{ display: "none" }}>
                      <BarToPrint ref={barRef}  />
                    </div>

                    <div style={{ display: "none" }}>
                      <KitchenToPrint ref={kitchenRef}  />
                    </div>

                    <div style={{ display: "none" }}>
                      <AllReceiptToPrint ref={allreceipt}  />
                    </div>

                  <div className="pb-4">
                    {(singleOrder?.get_division_names.replace(/\s+$/, '').includes("Grill Printer")) && (
                      <button type="button" onClick={handleGrill} className="print_inner_button text-white">Grill</button>
                    )}
                  </div>

                  <div className="pb-4">
                    {(singleOrder?.get_division_names.replace(/\s+$/, '').includes("Bar Printer")) && (
                      <button type="button" onClick={handleBar} className="print_inner_button text-white">Bar</button>
                    )}
                  </div>

                  <div className="pb-4">
                    <button type="button" onClick={handleKitchen} className="print_inner_button text-white">Kitchen</button>
                  </div>
                  
                  <div className="pb-4">

                    <button type="button" onClick={orderPrintHandler} className="print_inner_button text-white"> Customer</button>
                    </div>
                  
                  <div className="pb-4">

                    <button type="button" onClick={handleAllReceipt} className="print_inner_button text-white"> All</button>
                    </div>
                  <div className="pb-3">
                    <button type="button" onClick={setPrintModal} className="print_inner_button text-white">Close</button>
                    </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
