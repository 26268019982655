import { 
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_FAIL,

  WEATHER_REQUEST,
  WEATHER_SUCCESS,
  WEATHER_FAIL,

  CLOSED_REQUEST,
  CLOSED_SUCCESS,
  CLOSED_FAIL,

  TOGGLE_HEAVY_RAIN_REQUEST,
  TOGGLE_HEAVY_RAIN_SUCCESS,
  TOGGLE_HEAVY_RAIN_FAIL,

  TOGGLE_CLOSED_REQUEST,
  TOGGLE_CLOSED_SUCCESS,
  TOGGLE_CLOSED_FAIL,

  TOGGLE_WEATHER_REQUEST,
  TOGGLE_WEATHER_SUCCESS,
  TOGGLE_WEATHER_FAIL,

  TOGGLE_PAUSE_REQUEST,
  TOGGLE_PAUSE_SUCCESS,
  TOGGLE_PAUSE_FAIL
} from '../constant'
import axios from 'axios'
import { api_url, authHeader } from '../api/ApiUtills'

export const SettingAction=() => async (dispatch) =>{
  try{
    dispatch({type:SETTINGS_REQUEST})
      const {data} = await axios.get(`${api_url}/settings`,
        authHeader()
    )
    dispatch({
      type:SETTINGS_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:SETTINGS_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const WeatherAction=() => async (dispatch) =>{
  try{
    dispatch({type:WEATHER_REQUEST})
      const {data} = await axios.get(`${api_url}/weather_banner`,
        authHeader()
    )
    dispatch({
      type:WEATHER_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:WEATHER_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const ToggleWeatherAction=() => async (dispatch) =>{
  try{
    dispatch({type:TOGGLE_WEATHER_REQUEST})
      const {data} = await axios.post(`${api_url}/settings/toggle_weather`,
        {withCredentials: true},
        authHeader(),
    )
    dispatch({
      type:TOGGLE_WEATHER_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:TOGGLE_WEATHER_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const TogglePauseAction=() => async (dispatch) =>{
  try{
    dispatch({type:TOGGLE_PAUSE_REQUEST})
      const {data} = await axios.post(`${api_url}/settings/toggle_pause`,
        {withCredentials: true},
        authHeader(),
    )
    dispatch({
      type:TOGGLE_PAUSE_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:TOGGLE_PAUSE_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}


export const HeavyRainAction=(body) => async (dispatch) =>{
  try{
    dispatch({type:TOGGLE_HEAVY_RAIN_REQUEST})
      const {data} = await axios.post(`${api_url}/settings/toggle_heavy_rain`,
        {withCredentials: true},
        authHeader(),
    )
    dispatch({
      type:TOGGLE_HEAVY_RAIN_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:TOGGLE_HEAVY_RAIN_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const ToggleClosedAction=() => async (dispatch) =>{
  try{
    dispatch({type:TOGGLE_CLOSED_REQUEST})
      const {data} = await axios.post(`${api_url}/settings/toggle_closed`,
        {withCredentials: true},
        authHeader(),
    )
    dispatch({
      type:TOGGLE_CLOSED_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:TOGGLE_CLOSED_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const ClosedAction=() => async (dispatch) =>{
  try{
    dispatch({type:CLOSED_REQUEST})
      const {data} = await axios.post(`${api_url}/settings/toggle_closed`,
        {withCredentials: true},
        authHeader()
    )
    dispatch({
      type:CLOSED_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:CLOSED_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}