import { 
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_FAIL,

  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,

  ORDERS_HISTORY_REQUEST,
  ORDERS_HISTORY_SUCCESS,
  ORDERS_HISTORY_FAIL,

  ORDERS_UPCOMING_REQUEST,
  ORDERS_UPCOMING_SUCCESS,
  ORDERS_UPCOMING_FAIL,

  PREPARING_REQUEST,
  PREPARING_SUCCESS,
  PREPARING_FAIL,

  READY_REQUEST,
  READY_SUCCESS,
  READY_FAIL,

  ORDERS_ACCEPT_REQUEST,
  ORDERS_ACCEPT_SUCCESS,
  ORDERS_ACCEPT_FAIL,

  ORDERS_REJECT_REQUEST,
  ORDERS_REJECT_SUCCESS,
  ORDERS_REJECT_FAIL,

  ORDERS_READY_REQUEST,
  ORDERS_READY_SUCCESS,
  ORDERS_READY_FAIL,

  ORDERS_FAILED_REQUEST,
  ORDERS_FAILED_SUCCESS,
  ORDERS_FAILED_FAIL
} from '../constant'

export const OrderReducer = (state = {}, action)=>{
switch(action.type){
  case ORDER_REQUEST:
    return {loading:true}

  case ORDER_SUCCESS:
    return {loading:false, ordersDetails:action.payload}

  case ORDER_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const OrderDetailReducer = (state = {}, action)=>{
switch(action.type){
  case ORDER_DETAIL_REQUEST:
    return {loading:true}

  case ORDER_DETAIL_SUCCESS:
    return {loading:false, singleOrder:action.payload}

  case ORDER_DETAIL_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const OrdersUpcomingReducer = (state = {}, action)=>{
switch(action.type){
  case ORDERS_UPCOMING_REQUEST:
    return {loading:true}

  case ORDERS_UPCOMING_SUCCESS:
    return {loading:false, isSuccess:true, upcomingDetails:action.payload}

  case ORDERS_UPCOMING_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const PreparingReducer = (state = {}, action)=>{
switch(action.type){
  case PREPARING_REQUEST:
    return {loading:true}

  case PREPARING_SUCCESS:
    return {loading:false, PreparingOrder:action.payload}

  case PREPARING_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const ReadyReducer = (state = {}, action)=>{
switch(action.type){
  case READY_REQUEST:
    return {loading:true}

  case READY_SUCCESS:
    return {loading:false, ReadyOrder:action.payload}

  case READY_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const FailedReducer = (state = {}, action)=>{
switch(action.type){
  case ORDERS_FAILED_REQUEST:
    return {loading:true}

  case ORDERS_FAILED_SUCCESS:
    return {loading:false, FailedOrder:action.payload}

  case ORDERS_FAILED_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
  }
}

export const OrderAcceptReducer = (state = {}, action)=>{
switch(action.type){
  case ORDERS_ACCEPT_REQUEST:
    return {loading:true, isAccept:false}

  case ORDERS_ACCEPT_SUCCESS:
    return {loading:false, isAccept:true  }

  case ORDERS_ACCEPT_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const OrderRejectReducer = (state = {}, action)=>{
switch(action.type){
  case ORDERS_REJECT_REQUEST:
    return {loading:true}

  case ORDERS_REJECT_SUCCESS:
    return {loading:false }

  case ORDERS_REJECT_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const OrderReadyReducer = (state = {}, action)=>{
switch(action.type){
  case ORDERS_READY_REQUEST:
    return {loading:true}

  case ORDERS_READY_SUCCESS:
    return {loading:false, isSuccess:true }

  case ORDERS_READY_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}


