import { 
   ORDERS_COUNTS_REQUEST,
   ORDERS_COUNTS_SUCCESS,
   ORDERS_COUNTS_FAIL,

} from '../constant'

export const OrdercountsReducer = (state = {}, action)=>{
switch(action.type){
  case ORDERS_COUNTS_REQUEST:
    return {loading:true}

  case ORDERS_COUNTS_SUCCESS:
    return {loading:false, Counts:action.payload}

  case ORDERS_COUNTS_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}