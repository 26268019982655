import { 
   MENU_REQUEST,
   MENU_SUCCESS,
   MENU_FAIL,

   MENU_DETAIL_REQUEST,
   MENU_DETAIL_SUCCESS,
   MENU_DETAIL_FAIL

} from '../constant'

export const MenuReducer = (state = {}, action)=>{
switch(action.type){
  case MENU_REQUEST:
    return {loading:true}

  case MENU_SUCCESS:
    return {loading:false, menu_product:action.payload}

  case MENU_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const MenuDetailReducer = (state = {}, action)=>{
switch(action.type){
  case MENU_DETAIL_REQUEST:
    return {loading:true}

  case MENU_DETAIL_SUCCESS:
    return {loading:false, isSuccess:true, menu_detail_product:action.payload}

  case MENU_DETAIL_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

