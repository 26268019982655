import React, {useState, useEffect}  from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {signin} from '../redux/actions/LoginAction'

const LoginScreen = () => {
  const [email, setEmail]=useState('')
  const [password, setPassword]=useState('')
  const dispatch = useDispatch()
  const userLogin = useSelector(state=>state.userLogin)
  const {error,loading,isLoggedIn} = userLogin;

  const submitHandler = (e) =>{
    e.preventDefault()
    dispatch(signin(email,password))
  }

  useEffect (()=>{
     if (isLoggedIn){
      window.location.href = '/orders'
     }
   },[isLoggedIn])

  return (
    <>
      <div className="min-h-full flex items-center justify-center px-2 sm:px-6">
        <div className="max-w-md w-full">
          <div className="login_top">
            <h2 className=" text-center text-2xl font-extrabold text-gray-900">Log in</h2>
          </div>
          <form onSubmit={submitHandler}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm ">
              <div className= "input_field_margin">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full login_input block"
                  placeholder="Email address"
                  value ={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="w-full login_input block"
                  placeholder="Password"
                  value ={password}
                  onChange={(e)=>setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="login_button_log">
              <button
                type="submit"
                className="login_button "
              >
                LOG IN
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
    )
}

export default LoginScreen