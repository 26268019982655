import { 
 ORDERS_HISTORY_REQUEST,
 ORDERS_HISTORY_SUCCESS,
 ORDERS_HISTORY_FAIL
} from '../constant'
import axios from 'axios'
import { api_url, authHeader } from '../api/ApiUtills'

export const OrdersHistoryAction=() => async (dispatch) =>{
  try{
    dispatch({type:ORDERS_HISTORY_REQUEST})
      const {data} = await axios.get(`${api_url}/orders/history`, authHeader()
    )
    dispatch({
      type:ORDERS_HISTORY_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDERS_HISTORY_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}