import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import OrderDetail from "./OrderDetail"
import {useDispatch, useSelector} from 'react-redux'
import { PreparingAction, OrderAction, OrderDetailsAction } from '../../redux/actions/OrderAction'
import { OrdercountsAction } from '../../redux/actions/OrdercountsAction'
import { OrderPreparingSearchAction } from '../../redux/actions/SearchAction'
import moment from 'moment';

const Preparing = () => {
	const dispatch = useDispatch()
  const prepareData = useSelector(state=>state.prepare.PreparingOrder)
  const {records , total_count} = prepareData && prepareData.data || {};
  const [query, setQuery] = useState("")
	const [ searchNow, setSearchNow ] = useState("preparing")
	const [orderDetail, setOrderdetail] = useState(false)

   function loadData(){
	setOrderdetail(false)
	dispatch(PreparingAction())
	dispatch(OrdercountsAction())
   }

   useEffect (()=>{
	loadData()
   },[])
    
	const handleClick = (event,id) =>{
		event.preventDefault()
		dispatch(OrderDetailsAction(id))
		setOrderdetail(true)
	}

	const refreshClickHandler = (e) =>{
	  e.preventDefault()
	  dispatch(PreparingAction())
	}

	const searchHandler = (e) =>{
	  e.preventDefault();
	  setQuery(e.target.value);
	  dispatch(OrderPreparingSearchAction(query, searchNow))
	}

  const getOrderTime = (order) => {
    const { created_at } = order;
    var a = moment(created_at).format('lll')
    var b = moment().format('lll') 
    var minute = moment(b).diff(a, 'minutes')
    var hour = moment(b).diff(a, 'hours')
    var day = moment(b).diff(a, 'days')
      if(day < 0){
        return moment(b).diff(a, 'hours') + " " + "hours"
      }else if(day==1){
        return moment(b).diff(a, 'days') + " " + "day"
      }else if(hour < 24){
        return moment(b).diff(a, 'minutes') + " " + "mins"
      }else{
        return moment(b).diff(a, 'days') + " " + "days"
      }
  }
 
  return (
    <>
    <div className="tab_top_class">
      <div className="h-full tab_top_class_inner">

	   	  <div className="flex justify-start items-center relative tab_top_input_box">
	        <input
	          className="custom_search_input w-full outline-none"
	          type="text"
	          placeholder="Search by order id or coustomer name"
	          value={query}
	          onChange={searchHandler}
	        />
	        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M15.7812 13.8344L12.6656 10.7188C12.525 10.5781 12.3344 10.5 12.1344 10.5H11.625C12.4875 9.39688 13 8.00937 13 6.5C13 2.90937 10.0906 0 6.5 0C2.90937 0 0 2.90937 0 6.5C0 10.0906 2.90937 13 6.5 13C8.00937 13 9.39688 12.4875 10.5 11.625V12.1344C10.5 12.3344 10.5781 12.525 10.7188 12.6656L13.8344 15.7812C14.1281 16.075 14.6031 16.075 14.8938 15.7812L15.7781 14.8969C16.0719 14.6031 16.0719 14.1281 15.7812 13.8344ZM6.5 10.5C4.29063 10.5 2.5 8.7125 2.5 6.5C2.5 4.29063 4.2875 2.5 6.5 2.5C8.70938 2.5 10.5 4.2875 10.5 6.5C10.5 8.70938 8.7125 10.5 6.5 10.5Z" fill="black"/>
					</svg>
	      </div>

        <div className="orderDetailWrapper">
         {records && records.map((post) => (
	         <Link to="" onClick={event => handleClick(event, post.id)} key={post.id}>
			      <div className="prepare_inner">
			        <div className="preparing_page_inner_inside">
				      	<div className="new_page_inner1">
				      		<p className="prepare_inner_text">{post.id}</p>
				      		<p className="prepare_inner_text1"><span>{post.total_quantity} items </span> For {post.user.full_name}</p>
				      	</div>
				      	<div className="new_page_inner2">
				      		<div className="prepare_inner_h1">
                    {getOrderTime(post)}
				      		</div>
				      	</div>
				      </div>
			      </div>
			     </Link>
			    ))}
			  </div>

        <Link to="#" className="text-white" onClick={refreshClickHandler}>
			    <div className="order_refresh_tab">
	          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.2767 0.723252L10.9522 2.04775C9.81284 0.908147 8.23888 0.203125 6.5 0.203125C3.12884 0.203125 0.376391 2.85233 0.210996 6.18244C0.202389 6.35555 0.342139 6.5 0.515455 6.5H1.22754C1.38884 6.5 1.5224 6.37427 1.53154 6.21324C1.67987 3.59656 3.84549 1.52344 6.5 1.52344C7.87523 1.52344 9.11922 2.08008 10.0195 2.9805L8.64515 4.35487C8.4532 4.54683 8.58914 4.875 8.86059 4.875H12.4922C12.6605 4.875 12.7969 4.73858 12.7969 4.57031V0.938717C12.7969 0.667266 12.4687 0.531324 12.2767 0.723252ZM12.4845 6.5H11.7725C11.6112 6.5 11.4776 6.62573 11.4685 6.78676C11.3201 9.40344 9.15451 11.4766 6.5 11.4766C5.12477 11.4766 3.88078 10.9199 2.9805 10.0195L4.35485 8.64513C4.5468 8.45317 4.41086 8.125 4.13941 8.125H0.507812C0.339549 8.125 0.203125 8.26142 0.203125 8.42969V12.0613C0.203125 12.3327 0.531324 12.4687 0.723252 12.2767L2.04775 10.9522C3.18716 12.0919 4.76115 12.7969 6.5 12.7969C9.87119 12.7969 12.6236 10.1477 12.789 6.81756C12.7976 6.64445 12.6579 6.5 12.4845 6.5Z" fill="black"/>
						</svg>
						Refresh
			    </div>
		    </Link>
      </div>

      <div className="order_detail_page_top">
        {orderDetail==false ?(
        	<>
		        <div className="order_detail_page">
		       	  No Order Selected
		        </div>
          </>
        	) : (
          <>

            <OrderDetail 
				loadData ={loadData}
			/>
          </>
        	)}
      </div>
    </div>
    </>
  )
}

export default 	Preparing