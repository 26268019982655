import { 
   MENU_REQUEST,
   MENU_SUCCESS,
   MENU_FAIL,

   MENU_DETAIL_REQUEST,
   MENU_DETAIL_SUCCESS,
   MENU_DETAIL_FAIL
} from '../constant'
import axios from 'axios'
import { api_url, authHeader } from '../api/ApiUtills'

export const MenuAction=() => async (dispatch) =>{
  try{
    dispatch({type:MENU_REQUEST})
      const {data} = await axios.get(`${api_url}/menu_products`,
        authHeader()
    )
    dispatch({
      type:MENU_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:MENU_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const MenuDetailAction=(id, enable) => async (dispatch) =>{
  try{
    dispatch({type:MENU_DETAIL_REQUEST})
      const {data} = await axios.put(`${api_url}/menu_products/${id}`,
        { menu_product: {
          "enabled": `${enable}`
          }
        },
        authHeader(),
        {withCredentials: true},
    )
    dispatch({
      type:MENU_DETAIL_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:MENU_DETAIL_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}