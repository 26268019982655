import { 
 ORDER_REQUEST,
 ORDER_SUCCESS,
 ORDER_FAIL,

 PREPARING_REQUEST,
 PREPARING_SUCCESS,
 PREPARING_FAIL,

 READY_REQUEST,
 READY_SUCCESS,
 READY_FAIL,

 ORDERS_UPCOMING_REQUEST,
 ORDERS_UPCOMING_SUCCESS,
 ORDERS_UPCOMING_FAIL,

} from '../constant'
import axios from 'axios'
import { api_url, authHeader } from '../api/ApiUtills'


export const OrderSearchAction=(query, type) => async (dispatch) =>{
  try{
    dispatch({type:ORDER_REQUEST})
      const {data} = await axios(`${api_url}/orders/search?type=${type}&q=${query}`, 
        authHeader(),
        {withCredentials: true},
    )
    dispatch({
      type:ORDER_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDER_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderPreparingSearchAction=(query, type) => async (dispatch) =>{
  try{
    dispatch({type:PREPARING_REQUEST})
      const {data} = await axios(`${api_url}/orders/search?type=${type}&q=${query}`, 
        authHeader(),
        {withCredentials: true},
    )
    dispatch({
      type:PREPARING_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:PREPARING_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderReadySearchAction=(query, type) => async (dispatch) =>{
  try{
    dispatch({type:READY_REQUEST})
      const {data} = await axios(`${api_url}/orders/search?type=${type}&q=${query}`, 
        authHeader(),
        {withCredentials: true},
    )
    dispatch({
      type:READY_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:READY_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

export const OrderLaterSearchAction=(query, type) => async (dispatch) =>{
  try{
    dispatch({type:ORDERS_UPCOMING_REQUEST})
      const {data} = await axios(`${api_url}/orders/search?type=${type}&q=${query}`, 
        authHeader(),
        {withCredentials: true},
    )
    dispatch({
      type:ORDERS_UPCOMING_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDERS_UPCOMING_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}


