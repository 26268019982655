import { 
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_FAIL,
  
  WEATHER_REQUEST,
  WEATHER_SUCCESS,
  WEATHER_FAIL,

  TOGGLE_HEAVY_RAIN_REQUEST,
  TOGGLE_HEAVY_RAIN_SUCCESS,
  TOGGLE_HEAVY_RAIN_FAIL,

  TOGGLE_CLOSED_REQUEST,
  TOGGLE_CLOSED_SUCCESS,
  TOGGLE_CLOSED_FAIL,
  
  CLOSED_REQUEST,
  CLOSED_SUCCESS,
  CLOSED_FAIL,
} from '../constant'

export const SettingReducer = (state = {}, action)=>{
switch(action.type){
  case SETTINGS_REQUEST:
    return {loading:true}

  case SETTINGS_SUCCESS:
    return {loading:false, Setting:action.payload}

  case SETTINGS_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const WeatherReducer = (state = {}, action)=>{
switch(action.type){
  case WEATHER_REQUEST:
    return {loading:true}

  case WEATHER_SUCCESS:
    return {loading:false, isChecked:action.payload}

  case WEATHER_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const HeavyRainReducer = (state = {}, action)=>{
switch(action.type){

  case TOGGLE_HEAVY_RAIN_REQUEST:
    return {loading:true}

  case TOGGLE_HEAVY_RAIN_SUCCESS:
    return { heavy_rain:action.payload }

  case TOGGLE_HEAVY_RAIN_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}

export const ClosedReducer = (state = {}, action)=>{
switch(action.type){

  case CLOSED_REQUEST:
    return {loading:true}

  case CLOSED_SUCCESS:
    return {loading:false,  close_restaurant:action.payload}

  case CLOSED_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }

}