import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { OrderRejectAction, OrderAcceptAction } from '../../redux/actions/OrderAction'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";

export default function OptionModal({isOptions,setIsOptions}) {

  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { singleOrder } = useSelector(state=>state.singleOrderDetails)
  const id = singleOrder?.id

  const HandleClick = () =>{
    dispatch(OrderRejectAction(id))
    window.location.reload(); 
    setIsOptions()
  }
  const HandleComplete = () =>{
    dispatch(OrderAcceptAction(id))
    window.location.reload(); 
    setIsOptions()
  }

  return (
    <>
      <Transition appear show={isOptions} as={Fragment}>
        <Dialog
          as="div"
          className="fixed custom_inset z-10 overflow-y-auto"
          onClose={setIsOptions}
        >
          <div className="min-h-screen px-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block custom_bg1 w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl cancel_order_modal">
                <Dialog.Title
                  as="h3"
                  className="cancel_order_modal_heading text-white"
                >
                  
                </Dialog.Title>

                <div className="order_cancel_button1 justify-center flex">
                  <button
                    type="button"
                    className="inline-flex justify-center order_cancel_button_close text-white"
                    onClick={setIsOptions}
                  >
                    Close
                  </button>
                </div>

                <div className="order_cancel_button1 justify-center flex">
                   <button
                    type="button"
                    className="inline-flex justify-center order_cancel_button_cancel text-white"
                    onClick={HandleClick}
                  >
                    Cancel
                  </button>
                </div>
                <div className="order_cancel_button1 justify-center flex">
                  <button
                    type="button"
                    className="inline-flex justify-center order_cancel_button_complete text-white"
                    onClick={HandleComplete}
                  >
                    COMPLETE
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
