let token = JSON.parse(localStorage.getItem('userInfo'));

// const api_url = `http://localhost:3002/api/pos`
// const wss_url = `ws://localhost:3002/cable?type=user&token=${token}`;

const api_url = `https://application.damascus.my/api/pos`
const wss_url = `wss://application.damascus.my/cable?type=user&token=${token}`;

function authHeader() {
  
  return {
    headers:{
      'accept': 'application/json',
      'Content-Type': 'application/json',
      'Authentication-Token': token,
      'Authorization': `Bearer ${token}`,
    }
  };
}

export { api_url, wss_url, authHeader }
