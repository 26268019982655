import { toast } from "react-toastify";
import { 
   USER_LOGIN_REQUEST,
   USER_LOGIN_SUCCESS,
   USER_LOGIN_FAIL,
   USER_LOGOUT,
} from '../constant'
import axios from 'axios'
import { api_url, authHeader } from '../api/ApiUtills'

export const signin=(email, password) => async (dispatch) =>{
  try{
      dispatch({type:USER_LOGIN_REQUEST})
      
      const {data} = await axios.post(`${api_url}/users/sign_in`, {email, password}, )

      dispatch({
         type:USER_LOGIN_SUCCESS,
         payload:data
      })
   toast.success("Login Successful");
   localStorage.setItem('userInfo', JSON.stringify(data.token))
  }catch(error){
     dispatch({
      type:USER_LOGIN_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
     })
   toast.error("Invalid Email and Password");
  }
}

export const logout =() => (dispatch) =>{
   localStorage.removeItem('userInfo')
   dispatch({type:USER_LOGOUT})
}
