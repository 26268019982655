import { 
  ORDER_DETAIL_ID_REQUEST,
  ORDER_DETAIL_ID_SUCCESS,
  ORDER_DETAIL_ID_FAIL,
} from '../constant'
import axios from 'axios'
import { api_url, authHeader } from '../api/ApiUtills'


export const OrderDetailIdAction=(id) => async (dispatch) =>{
  try{
    dispatch({type:ORDER_DETAIL_ID_REQUEST})
      const {data} = await axios.get(`${api_url}/orders/${id}`, authHeader()
    )      
    dispatch({
      type:ORDER_DETAIL_ID_SUCCESS,
      payload:data
    })
  }catch(error){
    dispatch({
      type:ORDER_DETAIL_ID_FAIL,
      payload: error.response && error.response.data.message
      ?error.response.data.message
      :error.message,
    })
  }
}

