import React, { useState, useEffect } from 'react'
import { Tab } from '@headlessui/react'
import { Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { OrdercountsAction } from '../../redux/actions/OrdercountsAction'
import New from './New'
import Later from './Later'
import Preparing from './Preparing'
import Ready from './Ready'
import Failed from './Failed'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Orderscreen({match}) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const dispatch = useDispatch()

  const orderData = useSelector(state=>state.OrderCounts.Counts)
  const { ready, preparing, now, upcoming, failed } = orderData && orderData.data.records || {};

  useEffect (()=>{
      dispatch(OrdercountsAction())
  },[dispatch])

  return (
    <div>
      <div className="">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <header className="order_tab_background">
            <Tab.List className="flex space-x-1 custom_tab_class">
              <Tab
                className={({ selected }) =>
                  classNames(
                    'order_link custom_tab_botton',
                    selected
                      ? 'order_tab_active '
                      : 'hover:text-white'
                  )
                }
              >
                NEW <span className="now_order_length">{now}</span>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'order_link custom_tab_botton ',
                    selected
                      ? ' order_tab_active'
                      : 'hover:text-white'
                  )
                }
              >
                Preparing <span className="now_order_length1">{preparing}</span>
              </Tab>
                <Tab
                className={({ selected }) =>
                  classNames(
                    'order_link custom_tab_botton',
                    selected
                      ? 'order_tab_active'
                      : 'hover:text-white'
                  )
                }
              >
                Ready <span className="now_order_length1">{ready}</span>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'order_link custom_tab_botton',
                    selected
                      ? 'order_tab_active'
                      : 'hover:text-white'
                  )
                }
              >
                Later <span className="now_order_length1">{upcoming}</span>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'order_link custom_tab_botton',
                    selected
                      ? 'order_tab_active'
                      : 'hover:text-white'
                  )
                }
              >
                Failed <span className="now_order_length1">{failed}</span>
              </Tab>
            </Tab.List>
          </header>
          <Tab.Panels >
            <Tab.Panel> 
              <div>
                <New />
              </div> 
            </Tab.Panel>
            <Tab.Panel>
              <div>
                <Preparing /> 
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div>
                <Ready />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div>
                <Later />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div>
                <Failed />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
}
