import { 
 ORDERS_HISTORY_REQUEST,
 ORDERS_HISTORY_SUCCESS,
 ORDERS_HISTORY_FAIL
} from '../constant'

export const OrdersHistoryReducer = (state = {}, action)=>{
switch(action.type){
  case ORDERS_HISTORY_REQUEST:
    return {loading:true}

  case ORDERS_HISTORY_SUCCESS:
    return {loading:false, historyDetails:action.payload}

  case ORDERS_HISTORY_FAIL:
    return {loading:false, error:action.payload}

  default:
    return state
 }
}