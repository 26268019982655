import React, {useState, useEffect} from 'react'
import Header from '../component/Header/Header'
import OrderTab from '../component/Order/OrderTab'
import {SettingAction} from '../redux/actions/SettingAction'
import { OrdercountsAction } from '../redux/actions/OrdercountsAction'
import {MenuAction} from '../redux/actions/MenuAction'
import {useDispatch, useSelector} from 'react-redux'


const OrderScreen = () => {
  const dispatch = useDispatch()
  const setting = useSelector(state=>state.settings.Setting)
  const [weather, setWeather] = useState(false)
  const [closed, setClosed] = useState(false)
  const [pause, setPause] = useState(false)
  const orderData = useSelector(state=>state.menu.menu_product)
  const { disabled_total_count } = orderData && orderData.data || {};
  const orderNew = useSelector(state=>state.OrderCounts.Counts)
  const { now, preparing } = orderNew && orderNew.data.records || {};
  
  useEffect(()=>{
   setWeather(setting?.restaurant?.weather)
   setPause(setting?.restaurant?.pause)
   setClosed(setting?.restaurant?.closed)
   
   dispatch(SettingAction())
   dispatch(MenuAction())
   dispatch(OrdercountsAction())
  },[])

  return (
    <>
      <div className="Order_top_class">
       <div className="Order_top_inner">
       {pause ? (
        <>
         <div className="Order_top_inner4">
           <div className="Order_top_inner_img">
             <svg width="35" height="35" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM8 8C8 7.44772 8.44772 7 9 7H10C10.5523 7 11 7.44772 11 8V16C11 16.5523 10.5523 17 10 17H9C8.44772 17 8 16.5523 8 16V8ZM13 8C13 7.44772 13.4477 7 14 7H15C15.5523 7 16 7.44772 16 8V16C16 16.5523 15.5523 17 15 17H14C13.4477 17 13 16.5523 13 16V8Z" fill="black"/>
              </svg>
           </div>
           <div className="Order_top_inner_text">
              Restaurant in Pause Mode
           </div>
         </div>         
        </>
        ):null} 
       {closed ? (
        <>
         <div className="Order_top_inner1">
           <div className="Order_top_inner_img">
             <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M15.7683 0.625C18.8435 0.625 21.8497 1.6147 24.4067 3.46895C26.9637 5.3232 28.9566 7.95871 30.1335 11.0422C31.3103 14.1257 31.6183 17.5187 31.0183 20.7921C30.4183 24.0656 28.9375 27.0724 26.7629 29.4324C24.5884 31.7924 21.8179 33.3996 18.8017 34.0507C15.7855 34.7019 12.6592 34.3677 9.81803 33.0905C6.97686 31.8132 4.54848 29.6503 2.83995 26.8752C1.13143 24.1002 0.219513 20.8376 0.219513 17.5C0.219513 13.0245 1.85768 8.73225 4.77364 5.56757C7.68961 2.4029 11.6445 0.625 15.7683 0.625ZM19.0937 10.7324C19.534 10.2463 19.8099 9.85901 20.359 10.4578L22.1305 12.4161C22.7101 13.0368 22.6797 13.3994 22.1305 13.9789L18.7773 17.6071L22.004 21.109C22.4519 21.5869 22.8088 21.8863 22.2571 22.4823L20.4527 24.4049C19.8807 25.0339 19.5467 25.0009 19.0152 24.4049L15.7683 20.8728L12.5264 24.3912C11.995 24.9817 11.6609 25.0146 11.089 24.3912L9.28457 22.4686C8.72781 21.878 9.08464 21.5787 9.53764 21.0953L12.7593 17.6071L9.4187 13.9844C8.87459 13.4048 8.84422 13.0423 9.4187 12.4216L11.1902 10.4633C11.7343 9.85901 12.0102 10.2463 12.4556 10.7379L15.7683 14.3414L19.0937 10.7324Z" fill="#F44336"/>
              </svg>
           </div>
           <div className="Order_top_inner_text">
              Restaurant is CLOSED check setting
           </div>
         </div>         
        </>
        ):null}
        {now ? (
          <>
           <div className="Order_top_inner5">
             <div className="Order_top_inner_img">
             <svg width="30" height="35" viewBox="0 0 400 400"xmlns="http://www.w3.org/2000/svg" version="1.1">
              <path d="M 100 100 L 300 100 L 200 300 z"
                    fill="red" stroke="blue" stroke-width="3" />
            </svg>
             </div>
             <div className="Order_top_inner_text">
                {now} New Orders
             </div>
           </div>            
          </>
        ):null}
        {preparing ? (
          <>
           <div className="Order_top_inner5">
             <div className="Order_top_inner_img">
             <svg width="30" height="35" viewBox="0 0 400 400"xmlns="http://www.w3.org/2000/svg" version="1.1">
              <path d="M 100 100 L 300 100 L 200 300 z"
                    fill="yellow" stroke="blue" stroke-width="3" />
            </svg>
             </div>
             <div className="Order_top_inner_text">
                {preparing} Preparing Orders 
             </div>
           </div>            
          </>
        ):null}
        {disabled_total_count ? (
          <>
           <div className="Order_top_inner2">
             <div className="Order_top_inner_img">
              <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M15 0.625C17.9667 0.625 20.8668 1.6147 23.3335 3.46895C25.8003 5.3232 27.7229 7.95871 28.8582 11.0422C29.9935 14.1257 30.2905 17.5187 29.7118 20.7921C29.133 24.0656 27.7044 27.0724 25.6066 29.4324C23.5088 31.7924 20.8361 33.3996 17.9263 34.0507C15.0166 34.7019 12.0006 34.3677 9.25975 33.0905C6.51885 31.8132 4.17618 29.6503 2.52796 26.8752C0.879735 24.1002 0 20.8376 0 17.5C0 13.0245 1.58035 8.73225 4.3934 5.56757C7.20644 2.4029 11.0217 0.625 15 0.625ZM18.208 10.7324C18.6328 10.2463 18.8989 9.85901 19.4287 10.4578L21.1377 12.4161C21.6968 13.0368 21.6675 13.3994 21.1377 13.9789L17.9028 17.6071L21.0156 21.109C21.4478 21.5869 21.792 21.8863 21.2598 22.4823L19.519 24.4049C18.9673 25.0339 18.645 25.0009 18.1323 24.4049L15 20.8728L11.8726 24.3912C11.3599 24.9817 11.0376 25.0146 10.4858 24.3912L8.74512 22.4686C8.20801 21.878 8.55224 21.5787 8.98926 21.0953L12.0972 17.6071L8.87451 13.9844C8.34961 13.4048 8.32031 13.0423 8.87451 12.4216L10.5835 10.4633C11.1084 9.85901 11.3745 10.2463 11.8042 10.7379L15 14.3414L18.208 10.7324Z" fill="black"/>
              </svg>
             </div>
             <div className="Order_top_inner_text">
                {disabled_total_count} items out of stock
             </div>
           </div>            
          </>
        ):null}
        {weather ? (
          <>
           <div className="Order_top_inner3">
             <div className="Order_top_inner_img">
              <svg width="35" height="32" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.7395 0.168458C13.7102 0.388817 12.6813 0.817749 11.8359 1.38133C9.82664 2.71669 8.31948 5.39993 8.31948 7.65424V8.40174L7.2534 8.47531C5.28089 8.60996 3.62694 9.34532 2.25443 10.7053C-0.771665 13.7318 -0.747379 18.5343 2.30336 21.5483C3.74909 22.9819 5.7216 23.8147 7.48591 23.729L8.22091 23.6922L8.55163 22.6386L8.89485 21.5851L7.70626 21.5115C6.34625 21.4258 5.52553 21.1561 4.53302 20.4822C2.49979 19.0858 1.72764 16.1697 2.76908 13.9154C3.30801 12.7636 4.21481 11.8446 5.3666 11.2811L6.28554 10.8278L8.47877 10.8525L10.6595 10.8646L10.6227 8.95318C10.5859 7.18888 10.6106 6.96816 10.8799 6.22101C11.4681 4.59135 12.7299 3.28063 14.286 2.66776C15.3642 2.25133 17.0671 2.20205 18.1206 2.55741C19.1253 2.88812 19.9096 3.39063 20.6078 4.13778C21.306 4.88528 21.7471 5.74279 22.176 7.18852C22.36 7.82567 22.5435 8.3646 22.5682 8.40139C22.605 8.42567 23.23 8.33996 23.965 8.19317C26.3539 7.70316 28.1182 7.98495 29.7968 9.13675C31.1325 10.0435 32.1 11.4157 32.5168 12.9839C32.7497 13.8414 32.7497 15.5447 32.5168 16.4022C31.8675 18.8279 29.7968 20.8376 27.4689 21.3029C25.9375 21.6094 26.0721 21.499 25.7046 22.6508L25.3739 23.6922L25.8393 23.729C26.5132 23.7779 28.2654 23.3372 29.3432 22.8347C31.4015 21.8911 33.154 20.2251 34.0972 18.3261C34.8322 16.8436 35.0776 15.7164 34.9793 14.1971C34.8936 12.7146 34.6854 11.9671 33.9747 10.5214C33.4968 9.56568 33.2151 9.17353 32.345 8.30388C31.475 7.43388 31.0954 7.15209 30.1275 6.68673C29.4904 6.36815 28.6329 6.02494 28.2286 5.92708C27.2361 5.66993 25.6925 5.57172 24.835 5.71886C24.406 5.78029 24.1243 5.78029 24.1243 5.71886C24.1243 5.42493 23.2421 3.77099 22.74 3.09705C20.9514 0.744534 17.6556 -0.468691 14.7395 0.168458Z" fill="#00AAFB"/>
              <path d="M10.1081 22.0629C10.0099 22.4183 9.39738 24.6972 8.74809 27.123C8.0988 29.5491 7.54772 31.6319 7.51093 31.7423C7.44951 31.9384 7.55986 31.9626 8.67487 31.9626C9.36095 31.9626 9.91238 31.9259 9.91238 31.8891C9.91238 31.8523 10.5249 29.5612 11.2603 26.8044C12.0078 24.0354 12.6081 21.6954 12.6081 21.5972C12.6081 21.4622 12.3753 21.4258 11.4442 21.4258H10.2924L10.1081 22.0629Z" fill="#00AAFB"/>
              <path d="M14.1513 21.6343C14.1145 21.7568 13.502 24.0358 12.7913 26.6944C12.0809 29.353 11.4681 31.6319 11.4313 31.7423C11.3699 31.9384 11.4802 31.9626 12.5952 31.9626C13.2813 31.9626 13.8327 31.9259 13.8327 31.8891C13.8327 31.8523 14.4452 29.5612 15.1806 26.8044C15.9281 24.0354 16.5285 21.6954 16.5285 21.5972C16.5285 21.4622 16.2956 21.4258 15.3767 21.4258C14.3842 21.4258 14.2127 21.4504 14.1513 21.6343Z" fill="#00AAFB"/>
              <path d="M18.0721 21.6343C18.0353 21.7568 17.4228 24.0358 16.7121 26.6944C16.0014 29.353 15.3888 31.6319 15.3521 31.7423C15.2906 31.9384 15.401 31.9626 16.516 31.9626C17.2021 31.9626 17.7535 31.9259 17.7535 31.8891C17.7535 31.8523 18.366 29.5612 19.1014 26.8165C19.8489 24.0597 20.4492 21.7197 20.4492 21.6093C20.4492 21.4622 20.2532 21.4254 19.2975 21.4254C18.3049 21.4258 18.1332 21.4504 18.0721 21.6343Z" fill="#00AAFB"/>
              <path d="M21.9928 21.6343C21.956 21.7568 21.3435 24.0358 20.6328 26.6944C19.9221 29.353 19.3096 31.6319 19.2728 31.7423C19.2113 31.9384 19.3217 31.9626 20.4367 31.9626C21.1228 31.9626 21.6742 31.938 21.6742 31.9016C21.6742 31.8651 22.2867 29.5737 23.0221 26.8169C23.7696 24.0726 24.37 21.7201 24.37 21.6222C24.37 21.4629 24.186 21.4261 23.2182 21.4261C22.2253 21.4258 22.0539 21.4504 21.9928 21.6343Z" fill="#00AAFB"/>
              </svg>
             </div>
             <div className="Order_top_inner_text">
               Weather banner is on
             </div>
           </div>          
          </>
        ):null}
       </div>
       
       <div>
         <OrderTab />
       </div>
      </div>
    </>
  )
}

export default OrderScreen