import React, { useEffect, useState, useRef } from 'react'
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { OrderDetailIdAction} from '../../redux/actions/OrderAction'
import moment from 'moment';

const OrderDetailId = ({loadData}) => {
  const dispatch = useDispatch()
  const componentRef = useRef();
  const { singleOrder } = useSelector(state=>state.singleOrderDetails)
  const [orderDetailId, setOrderDetailId] = useState();
  const { id } = useParams();
  const dashboard_url = 'https://application.damascus.my/admin/orders/'
     
  useEffect(() => {
    dispatch(OrderDetailIdAction(id))
  }, []);

  return (
    <div className="order_detail_heading">
      <div className="order_detail_heading_top">
        <div className="order_detail_heading_nav">
          <div className="order_detail_heading_nav1">
            <Link to="/orders">
              <div className="prepare_inner">
                <div className="preparing_page_inner_inside">
                    <div className="prepare_inner_h1">
                      BACK
                    </div>
                </div>
              </div>
           </Link>
          </div>
          <div className="order_detail_heading_nav2">
          </div>
        </div>
        <div className="order_detail_heading_2nav">
        </div>
      </div>
      <main>
      <section className="dashboard dashboard-order">
        <div className="card order">
          <div className="card-content">
            <div className="is-flex is-justify-content-space-between">
              <div>
                <p>Order ID : {singleOrder?.id} </p>
                <div className="output m-0">
                Internal ID : {singleOrder?.internal_id}
                </div>
              </div>
              <div className="output mute m-0 has-text-right">
                <div>Creation Date : </div>
                {singleOrder?.created_at}
              </div>
            </div>
            <div className="is-flex is-justify-content-space-between">
              <div>
                <h4>External Delivery Fee : {singleOrder?.external_delivery_fee}</h4>
                <div className="output m-0"> Payment Gateway Fee : {singleOrder?.payment_gateway_fee}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card items">
          <div className="card-header">
          <p className="card-header-title">Items</p>
          </div>
          <div className="card-content">
          {singleOrder?.order_products.map((prod) => (
              <div className="order_detail_product" key={prod?.id}>
                  <div className="order_summary_inner_text">
                    {singleOrder?.cuttlery ? (<>
                    <div className="order_summary_inner1">
                      <img src="/images/frame2.png" alt="cutlry_img" />
                      Need cutlery
                    </div>
                    </>) : (<>
                      <div className="order_summary_inner1">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="10" cy="10" r="10" fill="#ED6C30"/>
                      <rect x="6" y="6" width="8" height="8" rx="2" fill="white"/>
                      </svg>
                      Don't need cutlery
                    </div>
                    </>)}
                  </div>
                  <div className="order_detail_product_inner">
                    <div className="order_detail_product_inner1">
                      <p className="order_detail_product_inner1_p">{prod?.quantity} <span className="order_detail_product_inner1_span">x</span> {prod.name}</p>
                    </div>
                    <div className="order_detail_product_inner2">
                      {prod.price}
                    </div>
                  </div>
                  {prod?.addons !="" ? <div className="choice_of_size">Add Ons</div> : null }
                  {prod?.addons?.map((AddOns) =>(
                      <div className="choice_of_size_regular" key={AddOns?.id}>
                        <p>{AddOns?.quantity} x {AddOns?.name}</p>
                        <p>{AddOns?.price}</p>
                      </div>                    
                  ))}
                  {prod?.modificators?.map((prodSize) =>(
                  <div key={prodSize?.group?.id}>
                <div className="choice_of_size" >{prodSize?.group?.name}</div>
                <div className="choice_of_size_regular">
                  <p>{prodSize?.group_item?.name}</p>
                  <p>{prodSize?.group_item?.price}</p>
                </div>
                </div>
                ))}
                <div className="choice_of_size">{prod.addon}</div>
                <div className="choice_of_size_regular">
                  <p>{prod.addon_item}</p>
                  <p>{prod.addon_price}</p>
                </div>
                {prod.note != "" ? (
                  <>
                    <div className="order_detail_add_ons">
                      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.0833 0H2.40833C1.49167 0 0.75 0.75 0.75 1.66667L0.758333 13.3333C0.758333 14.25 1.5 15 2.41667 15H10.75L15.75 10V1.66667C15.75 0.75 15 0 14.0833 0ZM4.91667 4.16667H11.5833C12.0417 4.16667 12.4167 4.54167 12.4167 5C12.4167 5.45833 12.0417 5.83333 11.5833 5.83333H4.91667C4.45833 5.83333 4.08333 5.45833 4.08333 5C4.08333 4.54167 4.45833 4.16667 4.91667 4.16667ZM7.41667 9.16667H4.91667C4.45833 9.16667 4.08333 8.79167 4.08333 8.33333C4.08333 7.875 4.45833 7.5 4.91667 7.5H7.41667C7.875 7.5 8.25 7.875 8.25 8.33333C8.25 8.79167 7.875 9.16667 7.41667 9.16667ZM9.91667 13.75V10C9.91667 9.54167 10.2917 9.16667 10.75 9.16667H14.5L9.91667 13.75Z" fill="#0F1B1A"/>
                      </svg>
                      {prod.note}
                    </div>
                  </>
                ):null}
              </div>
            ))}
            <div className="order_detail_page_price">
              <div className="order_price_top">
                <div className="order_subtotal">
                  <p> Subtotal </p>
                  <p> RM{singleOrder?.total_price} </p>
                </div>
                <div className="order_delivery">
                  <p>Delivery Fee</p>
                  <p>{singleOrder?.delivery_fee}</p>
                </div>
                {(singleOrder?.discount_data.length === 0 || singleOrder?.discount_data?.price_value > 0) ? ( 
                <div className="order_promo">
                  <p>{singleOrder?.discount_data?.name} Promo</p>
                  <p>-RM{singleOrder?.discount_data?.price_value}</p>
                </div>
                ) : ("")}
              </div>
              <div className="order_total">
                <p>Total</p>
                <p>RM{singleOrder?.summary_price}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card location">
            <div className="card-content">
              <div className="output inline with-icon m-0">
              {/* <img src="/assets/svg/icons/Address-af7d6bad7bc947e99b2a35cb19284ecba470c6047a8b5b9f246967c8a061f0d2.svg"> */}
                <label>Branch: </label>
                <div className="value">{singleOrder?.branch}</div>
              </div>
              <div className="output inline with-icon">
              {/* <img src="/assets/svg/icons/Pin-ed4e555827588feaf3ee15a592cb47162eb95cedd4ae5a2483ebc99b1082d4a9.svg"> */}
              <label>Delivery Address:</label>
              <div className="value"> {singleOrder?.address_data?.formatted_address}</div>
              </div>
            </div>
        </div>
        <div className="card status">
            <div className="card-content order_detail_inner2_p_2">
              <h3>{singleOrder?.status}</h3>
              {(singleOrder?.eta != null) ? ( 
                <div className="status-eta">ETA : {singleOrder?.eta.toString().slice(0,-6)}</div>
                ) : ("")
              }
              {(singleOrder?.schedule_at != null) ? ( 
                <div className="status-eta">Scheduled : {singleOrder?.schedule_at.toString().slice(0,-6)}</div>
                ) : ("")
              }
            </div>
        </div>
        <div className="card history">
          <div className="card-header">
            <p className="card-header-title">History</p>
          </div>
          <div className="card-content">
            {/* <div className="timelines"> */}
            {singleOrder?.order_status_transitions.map((order_status_transition) => (
              <div className="order_detail_product" key={order_status_transition?.id}>
                <div className="details">
                  <div className="timeline-title">{order_status_transition?.status}</div>
                  <div className="date">{order_status_transition?.created_at}</div>
                </div>
              </div>
            ))}
            </div>
        </div>
        <div className="card customer-details">
          <div className="card-header">
            <p className="card-header-title">Customer Details</p>
          </div>
          <div className="card-content">
            <div className="output m-0">
              <div className="with-avatar">
                <div className="avatar"></div>
                <span>
                  <div className="username big">
                  <a href="/admin/users/21">{singleOrder?.user?.full_name}</a>
                  </div>
                  <div className="output with-icon">
                  {/* <img src="/assets/svg/icons/Phone-2b08ce7d1dc427f61c42f6ec6365c1429de01e211e230fa0c03767bfea42feac.svg"> */}
                    <div className="value mute">{singleOrder?.user?.phone}</div>
                  </div>
                  <div>
                    <div>
                      <span><b>Payment method:</b></span>
                    </div>
                    <div>{singleOrder?.payment_method}</div>
                  </div>
                  <div>
                    <b>Order from:</b>
                    <span>{singleOrder?.user?.device_type}</span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card courier-details">
          <div className="card-header">
            <p className="card-header-title">Courier Details</p>
          </div>
          <div className="card-content">
            <div className="output m-0">
            <div className="with-avatar">
              <span>
                <div className="username big">{singleOrder?.delivery?.driver_name}</div>
                <div className="output with-icon">
                  {/* <img src="/assets/svg/icons/Phone-2b08ce7d1dc427f61c42f6ec6365c1429de01e211e230fa0c03767bfea42feac.svg"> */}
                  <div className="value mute">{singleOrder?.delivery?.driver_phone}</div>
                </div>
                <div>
                  <b>Tracking url : </b>
                  <span><a href={singleOrder?.delivery?.driver_share_link} target="_blank">{singleOrder?.delivery?.driver_share_link}</a></span>
                </div>
                <div>
                  <b>Receipt url : </b>
                  <span><a href={singleOrder?.delivery?.receipt_url} target="_blank">{singleOrder?.delivery?.receipt_url}</a></span>
                </div>
                <div>
                  <b>Dashboard url : </b>
                  <span><a href={dashboard_url+singleOrder?.internal_id} target="_blank" >{dashboard_url+singleOrder?.internal_id} </a></span>
                </div>
              </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card evaluation-details">
          <div className="card-header">
            <p className="card-header-title">Evaluation Details</p>
          </div>
          <div className="card-content outputs">
            <div className="columns">
              <div className="column">
              <label className="output">Food Evaluation</label>
              <div className="output">
                <div className="value">{singleOrder?.evaluation?.all_advantage}</div>
                <div className="rate rate-5">{singleOrder?.evaluation?.value} STARS</div>
              </div>
              </div>
              <div className="column">
                <label className="output">Driver Evaluation</label>
                <div className="output">
                  <div className="with-avatar">
                    <span>
                      <div className="username">{singleOrder?.delivery?.name}</div>
                    </span>
                  </div>
                  <div className="rate rate-5">{singleOrder?.evaluation?.driver_value} STARS</div>
                </div>
             </div>
            </div>
            <div className="columns">
              <div className="column">
                <label className="output">Note</label>
                <div className="output">
                  <div className="value">{singleOrder?.evaluation?.note}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </main>
    </div>
  )
}

export default OrderDetailId