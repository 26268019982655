import { 
  USER_LOGIN_REQUEST , 
  USER_LOGIN_SUCCESS, 
  USER_LOGIN_FAIL,
  USER_LOGOUT,
} from '../constant'

export const LoginReducer = (state = {}, action)=>{
switch(action.type){
  case USER_LOGIN_REQUEST:
    return {loading:true}

  case USER_LOGIN_SUCCESS:
    return {loading:false, isLoggedIn:true, userInfo:action.payload}

  case USER_LOGIN_FAIL:
    return {loading:false, error:action.payload}

  case USER_LOGOUT:
    return{}

  default:
    return state
 }
}
